import React, { Component } from "react";
import { Link } from "gatsby";
import heroBg from "../../assets/img/herobg.jpg";
import m1mask from "../../assets/img/m1-mask.png";
import m1maskMob from "../../assets/img/m1-mask-mob.png";
import BcLogo from "../../svg/bclogo.svg";
import ShopifyLogo from "../../svg/shopifypluslogo.svg";
import Shopify from "../../svg/shopify.svg";
import Magento from "../../svg/magento.svg";

class Hero extends Component {
  render() {
    const { config } = this.props;
    // const url = config.siteRss;
    // const { copyright } = config;
    // if (!copyright) {
    //   return null;
    // }

    return (
    <div className="hero_container">
        <div style={{ backgroundImage:`url(https://res.cloudinary.com/wearegoosebclandingpage/image/upload/q_auto:best/v1574882657/gatsby-cloudinary/herobg.jpg)`}} className="heroBg"></div>
        <div className="hero">
          <div className="hero__col hero__left">
            <div className="hero__left__bg display@md" style={{ backgroundImage:`url(${m1mask})`}} ></div>
            <div className="hero__left__bg hide@md" style={{ backgroundImage:`url(${m1maskMob})`}} ></div>
            <div className="hero__left__content">
              <div className="hero__left__bottom">
                <div className="hero__tagline">
                  <h2 className="display@md">We’re eCommerce specialists focusing solely on eCommerce marketing &amp; development.</h2>
                  <h2 className="hide@md">Magento Migration Experts.</h2>
                </div>
                <div className="hero__scroll">
                  Scroll
                </div>
              </div>
            </div>
          </div>
          <div className="hero__col hero__right">
            
            <div className="hero__heading">
              <h1 className="impact header">Magento <br />
                  Migration <br />
                  Experts
              </h1>
            </div>
            <div className="hero__platforms">
              <div className="hero__platforms__platform">
                <BcLogo />
              </div>
              <div className="hero__platforms__platform">
                <Magento />
              </div>
              <div className="hero__platforms__platform">
                <Shopify />
              </div>
            </div>
          </div>
      </div>
    </div>
    );
  }
}

export default Hero;
