import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Hero from "../components/Hero/Hero";
import Intro from "../components/Intro/Intro";
import Platforms from "../components/Platforms/Platforms";
import WhyGoose from "../components/WhyGoose/WhyGoose";
import Contact from "../components/Contact/Contact";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";



class Index extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <Header />
          <Hero />
          <Intro />
          <Platforms />
          <WhyGoose />
          <Contact  />
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
