import React, { Component } from "react";
import { Link } from "gatsby";
import Footer from "../Footer/Footer";

class Contact extends Component {
  render() {
    return (
    <div className="contact-container">
           <div className="contact__header__container">
            <div className="contact__header">
              <h4 className="header"> <a href="mailto:hello@weargoose.co.nz">Talk To Me Goose</a></h4>
              <div className="quote"><em>- Maverick (a.k.a you)</em></div>
             </div>
           </div>
          <Footer />
    </div>
    );
  }
}

export default Contact;
