import React, { Component } from "react";
import { Link } from "gatsby";
import heroBg from "../../assets/img/herobg.jpg";
import m1mask from "../../assets/img/m1-mask.png";
import BcLogo from "../../svg/bclogo.svg";
import ShopifyLogo from "../../svg/shopifypluslogo.svg";
import Shopify from "../../svg/shopify.svg";
import Magento from "../../svg/magento.svg";
import WhyGooseHero from "../../components/cloudinaryImages/whygoosehero";
import Craig from "../../components/cloudinaryImages/craig";
import Basky from "../../components/cloudinaryImages/basky";
import Cofounders from "../../components/cloudinaryImages/cofounders";

import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';


const MyComponent = () => {
  const data = useStaticQuery(graphql`
  query {
    file(name: { eq: "craig" }) {
      childCloudinaryAsset {
        fluid {
          ...CloudinaryAssetFluid
        }
      }
    }
  }
`);

  return <Image fluid={data.file.childCloudinaryAsset.fluid} alt="Magento 2 Developer Auckland" />;
}
class Hero extends Component {
  render() {
    return (
    <div className="why-goose-container">
      <div className="why-goose__hero__container">
        <div className="why-goose__hero">
          <WhyGooseHero/>
          <div className="why-goose__hero__header">
            <h2 className="header">Right Place. Right Time. <br />We Can Help.</h2>
          </div>
          <div className="why-goose__hero__subhead">
            <h3 className="header"><span class="split">Why</span> <span class="split">Choose</span> <span class="split">Goose?</span></h3>
          </div>
        </div>
      </div>
      <div className="why-goose-intro text-component">
        <p>We work with ambitious merchants helping them sell more, and grow fast. We deliver solid results from an adaptive, iterable approach. Our focus is crafting intelligent solutions to our clients biggest problems.</p>
        <div className="quote">"It takes a lot more than just fancy flying." - Charlie</div>
      </div>
      <div className="why-goose__approach text-component">
        <div className="why-goose__approach__item">
          <h3 className="header">Plan</h3>
          <p>You can never ask enough questions. We start by listening, asking…and listening some more, to gain a deep understanding of your goals.</p> 
          <p>This approach accelerates the strategic process, and builds consensus throughout the entire engagement.</p>
        </div>
        <div className="why-goose__approach__item">
          <h3 className="header">Deliver</h3>
          <p>Progressive enhancement is the key. We take an iterative approach. Picking off low hanging fruit and implementing industry standards. Massive impact, minimal fuss. </p>
          <p>The outcome; Measurable results within a short time period and a faster ROI.</p>
        </div>
        <div className="why-goose__approach__item">
          <h3 className="header">Iterate</h3>
          <p>Agile and always learning. We immerse ourselves in your brand.</p>
          <p>Together, month by month we surface actionable insights and make strategic recommendations. </p>
          <p> We deliver cost effective, incremental value that is sustainable and measurable.</p>
        </div>
      </div>
      <div className="why-goose__testimonials text-component">
        <div className="why-goose__testimonials__header">
          <h3 className="header">Clients Say Nice Things <br /> About Us.</h3>
        </div>
        <div className="why-goose__testimonials__quotes">
          <div className="why-goose__testimonials__quotes_quote">
            <blockquote className="testimonial" cite="https://www.clogger.co.nz">
                <p>Our new website is LIVE and we can't get enough of its new look. Sharper design, faster and easier navigation, refreshed product descriptions and an overall much better experience. I've been truly impressed by the speed, professionalism and clarity that they brought to the project.</p>
                <span className="testimonial__delivered">Delivered: Magento to BigCommerce Migration</span>
                <footer>Alienor <br />
                <cite>Marketing Manager <br /> <a href="https://www.clogger.co.nz">clogger.co.nz</a></cite></footer>
            </blockquote>
          </div>
          <div className="why-goose__testimonials__quotes_quote">
            <blockquote className="testimonial" cite="https://www.clogger.co.nz">
                <p>Goose was a great company to work with. Moving to a new E-Commerce platform was pain-free. Really enjoyed working with the team and communication was great. Timelines hit and was always available for a discussion in order to get things across the line.</p>
                <span className="testimonial__delivered">Delivered: Magento to BigCommerce Migration</span>
                <footer>Lesley <br />
                <cite>Director <br /> <a href="https://www.thetinshed.co.nz">thetinshed.co.nz</a></cite></footer>
            </blockquote>
          </div>
        </div>
      </div>
      <div className="why-goose__cofounders-intro text-component">
        <div className="why-goose__cofounders-intro__container">
           <div className="why-goose__cofounders-intro__image-container">
            <div className="why-goose__cofounders-intro__image">
              <Cofounders/>
            </div>
          </div>
          <div className="why-goose__cofounders-intro__header">
              <h3 className="header">Meet The <br />Co-founders.</h3>
            </div>
        </div>
      </div>
      <div className="why-goose__cofounders text-component">
        <div className="why-goose__cofounders__container">
          <div className="why-goose__cofounders--basky co-founder">
            <div className="co-founder__image">
              <Basky/>
            </div>
            <div className="co-founder__text">
              <h4 className="header">Baskar Shankara</h4>
              <span className="header__sub">Co-Founder / E-Commerce Marketing Expert</span>
              <p>Basky is an experienced digital marketing expert and co-founder of Goose Ltd. Basky oversees the day to day management of the company. He also leads our marketing team. From proposal to project delivery, he's involved in every step to ensure execution is aligned with his client's goals, and their customers needs.
              He moved to New Zealand in 2012. Basky has amassed a rich and diverse wealth of marketing knowledge, working across many different industries. He had senior marketing roles in large digital agencies, but wanted to challenge the status quo, and put customers goals before agency agendas. 
              He has a bachelors degree in computer science and PGDBA in marketing.</p>
              <span className="co-founder__funfact">
                <p><em><strong>Fun fact:</strong> Basky puts chilli sauce on everything!</em></p>
              </span>
            </div>
          </div>
          <div className="why-goose__cofounders--craig co-founder">
            <div className="co-founder__text co-founder__block">
                <h4 className="header">Craig Haggart</h4>
                <span className="header__sub">Co-Founder / E-Commerce Strategist &amp; Developer</span>
                <p>Craig is an experienced web developer, e-commerce expert and co-founder of Goose Ltd., where he oversees the creative and strategic direction of the company. He also leads our development team.</p>
                <p>He moved to New Zealand in 2013, having spent the previous seven years running a web design agency in the UK. He landed a role in a large corporate marketing agency, leading their web development team. After two years he decided he wanted to work for himself again and began planning what is now Goose.</p>
                <p>He has a bachelors degree in computer science.</p>
                <span className="co-founder__funfact">
                  <p><em><strong>Fun fact:</strong> Craig migrated to New Zealand to go trout fishing!!</em></p>
                </span>
            </div>
            <div className="co-founder__image co-founder__block">
                {/* <img src=<Clouds} alt="Magneto Migration Experts" /> */}
                <Craig/>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Hero;
