import React, { Component } from "react";
import { Link } from "gatsby";
import heroBg from "../../assets/img/herobg.jpg";
import m1mask from "../../assets/img/m1-mask.png";
import BcLogo from "../../svg/bclogo.svg";
import ShopifyLogo from "../../svg/shopifypluslogo.svg";
import Shopify from "../../svg/shopify.svg";
import Magento from "../../svg/magento.svg";

class Hero extends Component {
  render() {
    return (
    <div className="intro-container">
      <div className="intro text-component">
        <div className="intro__heading">
          <h2 className="intro__heading__header">
            <span className="split">Every Maverick</span>
            <span className="split"> Needs a Goose.</span>
          </h2>
        </div>
        <div className="intro__text">
          <p>Your BigCommerce Wingman. Proactive and reliable in nature. We have your back. As trusted BigCommerce experts in New Zealand, we work with ambitious merchants helping them sell more. Replatform without breaking a sweat, and begin future proofing your business. Let's accelerate your orders and goose the metrics that matter.</p>
          <div className="quote">
            "It's time to buzz the tower." - Maverick
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Hero;
