import React, { Component } from "react";
import { Link } from "gatsby";
import heroBg from "../../assets/img/herobg.jpg";
import m1mask from "../../assets/img/m1-mask.png";
import BcLogo from "../../svg/bigcommercelogo.svg";
import ShopifyLogo from "../../svg/shopifypluslogo.svg";
import Shopify from "../../svg/shopify.svg";
import Magento from "../../svg/magentologo.svg";

class Platforms extends Component {
  render() {
    return (
    <div className="platforms">
      <div className="platforms__logos">
        <ul className="platforms__list">
          <li className="platforms__list-item platforms__list-item--bc text-component">
            <h3 className="header">BigCommerce &amp; BigCommerce Enterprise</h3>
            <BcLogo />
          </li>
          <li className="platforms__list-item platforms__list-item--shopify">
            <h3 className="header">Shopify &amp; Shopify Plus</h3>
            <ShopifyLogo />
          </li>
          <li className="platforms__list-item platforms__list-item--m2">
            <h3 className="header">Magento 2 CE &amp; Magento 2 EE</h3>
            <Magento />
          </li>
        </ul>
      </div>

      {/* BigCommerce */}
      <div className="platforms__platform platforms__platform--bc">
        <div className="text-component u-container">
          <div className="platform__logo">
            <BcLogo />
          </div>
          <h2 className="platform__header">Why Choose BigCommerce?</h2>
          <div className="platform__intro">
            <p className="platform__intro__text">BigCommerce gives you the ability to innovate, outperform and overachieve - no wonder it is the industry’s most versatile solution for advancing your commerce experiences. A highly-extensible SaaS solution that takes the onus of security and scalability to fast-track your growth.</p>
            <div className="platform__intro__quote quote quote--white">"Let's turn and burn!" - Nick, in the air</div>
          </div>
          <div className="u-container-md">
            <div className="platform__quickfacts">
              <div className="platform__quickfacts__fact up">
                <h3 className="header">96% of brands relaunch in 2-4 months.</h3>
                <p>Proven. In fact, Goose have migrated Magento 1 stores to BigCommerce in under a month! BigCommerce accelerates growth, not complexity.</p>
              </div>
              <div className="platform__quickfacts__fact ">
                <h3 className="header">20% faster than other leading ecommerce platforms.</h3>
                <p>Studies show that fast websites result in a better conversion rate. In other words, the quicker your store, the more orders you're going to get.</p>
              </div>
              <div className="platform__quickfacts__fact up">
                <h3 className="header">99.99% median up-time.</h3>
                <p>Leveraging the power of Google Cloud Platform, BigCommerce offers unparalleled bandwidth blazing-fast speed. More time generating sales, not maintaining your site.</p>
              </div>
              <div className="platform__quickfacts__fact">
                <h3 className="header">Headless commerce out-of-the-box, across any front-end.</h3>
                <p>Headless commerce meets you where you need custom commerce functionality. Deliver API-driven experiences through your CMS or custom front-end with BigCommerce.</p>
              </div>
              <div className="platform__quickfacts__fact up">
                <h3 className="header">90% of platform data is exposed to APIs.</h3>
                <p>Flexibility made easy. The open API and the use of custom fields makes BigCommerce extremely flexible and the platform can do virtually anything you want it to do.</p>
              </div>
              <div className="platform__quickfacts__fact">
                <h3 className="header">Built for speed and security</h3>
                <p>Re-platform so that your team can focus on innovation, not issues.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="platforms__platform platforms__platform--shopify">
        <div className="text-component u-container">
            <div className="platform__logo">
              <ShopifyLogo />
            </div>
            <h2 className="platform__header">Why Choose Shopify?</h2>
            <div className="platform__intro">
              <p className="platform__intro__text">A powerful ecommerce platform with the freedom to grow, adapt, and evolve. Costly builds, crashed sites, and infrastructure that burns through development resources are all things of the past. 
              Shopify Plus gives you a multi-channel platform with unmatched scalability so you can grow your business, instead of worrying about your website.</p>
              <div className="platform__intro__quote quote quote--white">"Show me the way home, honey." - Goose</div>
            </div>
            <div className="u-container-md">
              <div className="platform__quickfacts">
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">Trusted by over 800,000 businesses worldwide.</h3>
                  <p>It's no secret that Shopify is the most popular ecommerce platform worldwide.</p>
                </div>
                <div className="platform__quickfacts__fact ">
                  <h3 className="header">A marketing and sales powerhouse.</h3>
                  <p>Automate campaigns, product drops, and high-demand sales.</p>
                </div>
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">An end-to-end eCommerce solution.</h3>
                  <p>Shopify handles everything from marketing, payments, to secure checkout and shipping.</p>
                </div>
                <div className="platform__quickfacts__fact">
                  <h3 className="header">Customize your storefront, checkout, and backend systems.</h3>
                  <p>No design skills needed. You have complete control over the look and feel of your website, from its layout to content and colors.</p>
                </div>
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">Safe and Secure.</h3>
                  <p>Level 1 web security. Unleash IT from repetitive tasks, maintaining performance, and monitoring risks.</p>
                </div>
                <div className="platform__quickfacts__fact">
                  <h3 className="header">Multi-channel Sales.</h3>
                  <p>Launch new sales channels across marketplaces, social, mobile, and in real life. Go global through dedicated storefronts in multiple languages and currencies.</p>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="platforms__platform platforms__platform--m2">
        <div className="text-component u-container">
            <div className="platform__logo">
              <Magento />
            </div>
            <h2 className="platform__header">Why Choose Magento 2?</h2>
            <div className="platform__intro">
              <p className="platform__intro__text"> Magento 2.0 provides integrated core commerce features including cloud hosting and deployment, support, business intelligence and tools to accelerate your sales. Magento is hands down the most customisable platform on the market. Complex business logic? Don't worry Magento can handle it.</p>
              <div className="platform__intro__quote quote quote--white">"It's time for the big one." - Goose</div>
            </div>
            <div className="u-container-md">
              <div className="platform__quickfacts">
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">Deliver a truly unique experience.</h3>
                  <p>Customisable and agile, Magento gives your business the flexibility other platforms cannot.</p>
                </div>
                <div className="platform__quickfacts__fact ">
                  <h3 className="header">Feature, Features, Features!!</h3>
                  <p>Did we mention how many out-of-the-box features and seamless third-party integrations features M2 ships with?</p>
                </div>
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">Multi store &amp; multi currency.</h3>
                  <p>Yet, another feature. Magento delivers multi store &amp; multi currency as a fully fledged core feature of the platform. </p>
                </div>
                <div className="platform__quickfacts__fact">
                  <h3 className="header">Advanced CMS capabilities.</h3>
                  <p>Out of all of the platforms on the market Magento delivers the best CMS experience on the market.</p>
                </div>
                <div className="platform__quickfacts__fact up">
                  <h3 className="header">Expert support &amp; community</h3>
                  <p>24/7 support and over 315,000 developers and partners.</p>
                </div>
                <div className="platform__quickfacts__fact">
                  <h3 className="header">Open-source</h3>
                  <p>When it comes to full-fledged shopping cart solution for large-scale needs, few can compete with Magento.</p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    );
  }
}

export default Platforms;
