import React, { Component } from "react";
import { Link } from "gatsby";
import Goose from "../../svg/gooselogo.svg";

class Header extends Component {
  render() {
    return (
      <header className="main-header">
      <div className="main-header__logo">
        <Goose />
      </div>
      <div className="main-header__cta">
        <a className="link" href="mailto:hello@wearegoose.co.nz">Get in touch</a>
      </div>
    </header>
    );
  }
}

export default Header;
