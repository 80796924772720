import React, { Component } from "react";
import { Link } from "gatsby";
import Location from "../../svg/location.svg";
import Youtube from "../../svg/youtube.svg";
import Instagram from "../../svg/instagram.svg";
import Facebook from "../../svg/facebook.svg";

class Footer extends Component {

  render() {
  
    function Year() {
      const dt = new Date();
      return dt.getFullYear();
    }

    return (
        <footer className="footer text-component">
          <div className="footer__location footer__block location">
            <div className="location__icon">
              <Location />
            </div>
            <div className="location__address">
              <p><a href="https://goo.gl/maps/25DmSReXPUw7vcVu7">GridAKL 101 Pakenham St W, <br />
              Wynyard Quarter, Auckland.</a></p>
            </div>        
          </div>
          <div className="footer__contact footer__block">
            <p><a href="tel:092188821">092188821</a><br />
            <a mailto="hello@wearegoose.co.nz" href="#">hello@wearegoose.co.nz</a> </p>       
          </div>
          <div className="footer__copyrights footer__block">
            <p><span aria-label="copyright" role="img" data-reactid="227">©</span>  <Year/> Goose Ltd - Magento Migration Experts.</p>
          </div>
          <div className="footer__socials footer__block">
            <div className="social__icons">
              <div className="socail__icons__icon">
                <a href="https://www.facebook.com/wearegoose/"><Facebook /></a>
              </div>
              <div className="socail__icons__icon">
                <a href="https://www.youtube.com/channel/UCkDD0knOW6Hbj9MJMruVgrg"><Youtube /></a>
              </div>
              <div className="socail__icons__icon">
                <a href="https://www.instagram.com/wearegoose.co.nz/"><Instagram /></a>
              </div>
            </div>
          </div>
      </footer>
    );
  }
}

export default Footer;
